import { Paragraph } from 'components/Paragraph';
import { lightTheme } from 'lib/theme';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

export const NotFound = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Page Not Found - Sessions</title>
    </Helmet>
    <ThemeProvider theme={lightTheme}>
      <Paragraph>Not Found</Paragraph>
    </ThemeProvider>
  </>
);
